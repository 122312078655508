import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import './../assets/css/ecologique.css'

import onlyLogoHome from './../assets/img/only_logo_home.svg'
import subtitleWhiteStroke from './../assets/img/subtitle_white_stroke.svg'
import whiteArrow from './../assets/img/white_arrow.svg'
import earthComponentsResponsive from './../assets/img/earth_components_responsive.svg'
import earthComponents from './../assets/img/earth_components.svg'
import subtitleBlue from './../assets/img/subtitle_blue_stroke.svg'
import blackStroke from './../assets/img/black-stroke.png'

const Ecologique = () => {
  return (
    <>
      <Helmet>
        <title>Impact écologique | Paramedic</title>
      </Helmet>
      <section id='section_ecologique_home'>
        <div className='container-fluid'>
          <div className='row' id='home-principal-row'>
            <div className='col-lg-6 left_home delay_500'>
              <div className='row'>
                <img
                  draggable='false' src={onlyLogoHome} alt='Logo Paramedic Blanc'
                  className='logo_home' data-aos='fade-right' data-aos-duration='1000'
                />
              </div>
              <h1>Impact Ecologique</h1>
              <img
                draggable='false' src={subtitleWhiteStroke}
                alt='Traits blancs à bouts arrondis' className='subtitle_white_stroke' data-aos='fade-right'
                data-aos-duration='1000' data-aos-delay='500'
              />
              <p data-aos='fade-right' data-aos-duration='1000' data-aos-delay='650'>L’avenir de la planète est
                notre priorité. Changeons nos habitudes de consommation en faisons mieux avec moins. Paramedic
                s’engage à surveiller son impact écologique, faites de même !
              </p>
              <Link to='#section_ecologique_benefices'><img
                draggable='false' src={whiteArrow}
                alt='Flèche blanche orientée vers le bas'
                className='white_arrow slide-bottom-faster'
                                                       />
              </Link>
            </div>
            <div className='col-lg-6 right_home'>
              <img
                draggable='false' className='slide-bottom delay_100 responsive'
                src={earthComponentsResponsive}
                alt="Illustration d'objets en apesanteur sur un globe"
              />
              <img
                draggable='false' className='desktop' src={earthComponents}
                alt="Illustration d'objets en apesanteur sur un globe"
              />
            </div>
          </div>
        </div>
      </section>

      <section id='section_ecologique_benefices'>
        <div className='container-fluid'>
          <div className='row benefices-content'>
            <div className='col-md-5 benefices-title' data-aos='fade-down' data-aos-duration='1500'>
              <h3>Les bénéfices écologiques sur le terrain</h3>
              <img
                draggable='false' src={subtitleBlue}
                alt='Traits blancs à bouts arrondis' className='subtitle_blue_stroke'
              />
            </div>
          </div>
          <div className='row benefices-cards'>
            <div className='card-container col-lg-4 col-xl-3' data-aos='fade-down' data-aos-duration='1500'>
              <div className='card'>
                <h5>Économies de carburants</h5>
                <img draggable='false' src={blackStroke} alt='Trait noir' className='black-stroke' />
                <p>Grâce à son algorithme, Paramedic délivre les transports en fonction de la zone d’activités
                  de chaque société. La géolocalisation permet également d’attribuer des transports immédiats
                  aux ambulanciers proches d’un lieu de prise en charge.
                </p>
                <br />
                <p>De cette manière, les transporteurs optimisent leur trajet et consomment moins de carburant.
                </p>
              </div>
            </div>
            <div className='card-container col-lg-4 col-xl-3' data-aos='fade-up' data-aos-duration='1500'>
              <div className='card'>
                <h5>Diminution des retours à vide</h5>
                <img draggable='false' src={blackStroke} alt='Trait noir' className='black-stroke' />
                <p>La technologie Paramedic distribue les transports intelligemment et assure ainsi la réduction
                  des retours à vide pour les transporteurs.
                </p>
                <br />
                <p>Les sociétés de transporteurs réduisent ainsi leur impact écologique.</p>
              </div>
            </div>
            <div className='card-container col-lg-4 col-xl-3' data-aos='fade-down' data-aos-duration='1500'>
              <div className='card'>
                <h5>Incitation au transport partagé</h5>
                <img draggable='false' src={blackStroke} alt='Trait noir' className='black-stroke' />
                <p>Grâce à la géolocalisation et le système d’ordonnancement des transports, Paramedic propose
                  automatiquement la prise en charge simultanée de patients selon leur lieu de prise en charge
                  et de destination.
                </p>
              </div>
            </div>
          </div>
          <div className='row buttons-container' data-aos='fade-up' data-aos-duration='1500'>
            <div className='col-sm-7 col-md-4 col-lg-4 col-xl-3 button-container'>
              <Link to='/transport' className='light-button'>Souscrire en tant que société d’ambulance</Link>
            </div>
            <div className='col-sm-7 col-md-4 col-lg-4 col-xl-3 button-container'>
              <Link to='/form_sante' className='dark-button'>Souscrire en tant qu’établissement de santé</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Ecologique
